<script>
export default {
  props:{
    props: Object
  }
}
</script>

<template>
  <div class="section-with-simple-list py-5">
    <div class="row">
      <div class="col-12 text-center">
        <h2 class="font-weight-bold">{{props.title}}</h2>
      </div>
      <div class="col-12 text-center">
        <p class="lead text-muted">{{props.description}}</p>
      </div>
      <div class="col-12 my-5">
        <div class="card mb-3 border-0 rounded bg-light"
          v-for="(item, index) in props.exclusions"
          v-bind:key="index">
          <div class="card-body">
            <p class="text-dark mb-0">
              {{item}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>